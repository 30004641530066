@charset "utf-8";

@import '../../../../style.scss';
@import '../../style.scss';
@import 'bulma/sass/components/card.sass';
@import 'bulma/sass/elements/button.sass';


.channel-item {
    $channel-item-color: $background-dark-2;

    //@extend .card;
    //@extend .button;
    @extend .mb-1;
    @extend .p-2;

    flex: 0 0 5rem;
    display: flex;
    height: 5rem;
    overflow: hidden;
    color: white;

    &.global-channel {
        /*background-color: rgb(0, 64, 128);*/
        background-color: var(--col-bg-accent-400);
    }

    &.current-conversation-channel {
        background-color:  rgb(96, 0, 128);
        /*background-color: var(--col-bg-accent-200);*/
    }

    &.other-channel {
        background-color: $channel-item-color;
    }

    .back-button {
        width: 3rem;
        margin-right: -1rem;
        z-index: 20;

        .back-button-icon {
            width: 3rem;
            height: 4rem;
            fill: #fff;
        }
    }

    .channel-icon {
        @extend .mr-2;

        flex: 0 0 auto;
        display: flex;
        align-items: center;
        position: relative;
        width: $channel-icon-width;

        .icon-img {
            position: absolute;
            width: $avatar-icon-size;
            height: $avatar-icon-size;
            border-radius: 50%;
            overflow: hidden;
            filter: drop-shadow(0 0 0.3rem rgba(0, 0, 0, 0.4));
        }

        .icon-svg-container {
            position: relative;
            width: $channel-icon-width;
            padding-left: 1rem;
            border-radius: 50%;
            overflow: hidden;

            .icon-svg {
                width: $avatar-icon-size;
                height: $avatar-icon-size;
                fill: $bg-color;
                filter: drop-shadow(0 0 0.3rem rgba(0, 0, 0, 0.4));
            }
        }
    }

    .description-block {
        display: flex;
        flex-direction: column;
        justify-content: center;

        .title-row {
            //flex: 1 1 60%;
            flex: 1 1 auto;
            padding-top: 0.8rem;

            .title {
                @extend .mr-1;

                //flex: 1 1 auto;
                flex: 1 1 100%;
                width: 100%;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                font-size: 1.4rem;
            }
        }

        .detail-row {
            flex: 1 1 40%;
            width: 100%;
            overflow: hidden;
            color: #ddd;
            white-space: nowrap;
            text-overflow: ellipsis;
            font-size: 0.9rem;
        }
    }

    .channel-label {
        @extend .mx-2;

        flex: 0 0 auto;
        font-size: 1rem;

        &::before {
            content: "[";
        }
        &::after {
            content: "]";
        }

        &.global {
            //color: rgb(51, 153, 255);
            //color:  rgb(179, 102, 255);
            color: #ccc;
        }

        &.active-conversation {
            //color: rgb(0, 204, 102);
            color: #ccc;
        }

        &.inactive-conversation {
            color: rgb(102, 153, 153);
        }

        &.private {
            color: rgb(198, 83, 140);
        }
    }

    //.channel-type-label {
        //@extend .channel-label;
//
        //&.type-global {
            //color: rgb(51, 153, 255);
        //}
//
        //&.type-conversation {
            //color:  rgb(179, 102, 255);
        //}
//
        //&.type-private {
            //color: rgb(198, 83, 140);
        //}
    //}
//
    //.channel-membership-label {
        //@extend .channel-label;
//
        //&.membership-joined {
            //color: rgb(0, 204, 102);//rgb(0, 179, 89);
        //}
//
        //&.membership-left {
            //color: rgb(102, 153, 153);
        //}
    //}
}

@import '../../style.scss';

.mingle-area {
    position: relative;
    width: 100%;
    height: 100%;
    //padding: 0.5rem;
    //overflow: visible;
    overflow-x: hidden;
    overflow-y: auto;
    z-index: $z-index-conversation-area;
    //background-color: var(--col-bg-400);
    background: linear-gradient(-45deg, var(--col-bg-accent-200), var(--col-hl-1));

    .placement-container {
        position: absolute;
        transform: translate(-50%, -50%);
        z-index: 300;
    }

    .simulation-svg {
        position: absolute;
        z-index: 310;
        pointer-events: none;

        .simulation-item {
            //visibility: hidden;
            //pointer-events: auto;
            fill: rgba(0, 0, 0, 0.0);
        }
    }
}


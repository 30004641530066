.selfie-container {
    $display-size: 480px;

    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;

    .selfie-display {
        position: relative;
        width: $display-size;
        height: $display-size;
        border-radius: 50%;
        object-fit: cover;

        -moz-transform: scale(-1, 1);
        -webkit-transform: scale(-1, 1); -o-transform: scale(-1, 1);
        transform: scale(-1, 1);
    }

    .selfie-placeholder {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        width: $display-size;
        height: $display-size;
        background: transparent;

        .selfie-prompt {
            font-size: 1.4rem;
            text-align: center;
        }
    }
}

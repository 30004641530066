@import '../../../../style.scss';

.customization-form {
    display: flex;
    flex-direction: column;
    //justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;

    .looks-popup {
        position: absolute;
        width: 48rem;
        max-width: 90vw;
        max-height: 70vh;
        border-radius: $border-radius-popup;
        //background-color: rgba(var(--col-bg-spread-800), 0.75);
        background-color: var(--col-bg-800);
        filter: $shadow;
        z-index: $z-index-sidebar;
        overflow-y: auto;

        .avatar-selection-group {
            width: 100%;
            margin-top: 2rem;
            //margin: auto;

            .avatar-option {
                width: 6rem;
                height: 6rem;
                padding: 0.5rem;
                border-radius: 0.8rem;
                //box-sizing: border-box;

                &:hover {
                    background-color: var(--col-bg-500);
                }

                &.selected {
                    padding: 0.25rem;
                    border: 0.3rem solid var(--col-hl-1);
                    //background-color: #555;
                }

                .radio-button {
                    margin: 0;
                    padding: 0;
                    appearance: none;
                }

                .avatar-image {
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }

    .avatar-preview {
        position: relative;
        z-index: 0;
        margin-top: 1rem;
    }

    .disabled-button {
        filter: grayscale(80%);
    }
}

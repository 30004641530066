.peer-grid {
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    width: 100%;
    height: 100%;

    .peer-grid-row {
        flex: 1 1 auto;
        display: flex;
        flex-direction: row;
        justify-content: center;
        width: 100%;
    }
}

@charset "utf-8";

@import '../../../../style.scss';
@import '../../../../../node_modules/bulma/sass/components/panel.sass';


.channel-list {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: auto;
    //@extend .panel;
}

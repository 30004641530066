@import '../../../../style.scss';


.speaker-view {
    $sidebar-controls-size: 3rem;
    $controls-border-width: 0.2rem;

    display: flex;
    align-items: center;
    overflow: hidden;
    width: 100%;
    height: 100%;
    background-color: $background-dark-1;

    @media screen and (orientation:landscape) {
        flex-direction: row;
    }

    @media screen and (orientation:portrait) {
        flex-direction: column-reverse;
    }

    .speaker-area {
        flex: 1 1 auto;;
        position: relative;

        @media screen and (orientation:landscape) {
            flex-direction: column;
            height: 100%;
            width: 80%;
        }

        @media screen and (orientation:portrait) {
            flex-direction: row;
            height: 80%;
            width: 100%;
        }
    }

    .peers-sidebar {
        flex: 0 1 auto;
        display: flex;
        position: relative;
        //background-color: $background-dark-2;

        @media screen and (orientation:landscape) {
            @include transition-size(width, 20%, 0%);

            height: 100%;
            flex-direction: column;
        }

        @media screen and (orientation:portrait) {
            @include transition-size(height, 20%, 0%);

            width: 100%;
            flex-direction: row;
        }

        .peers-sidebar-content {
            flex: 1 1 auto;
            display: flex;
            width: 100%;
            height: 100%;

            @media screen and (orientation:landscape) {
                flex-direction: column;
            }

            @media screen and (orientation:portrait) {
                flex-direction: row;
            }
        }

        .peers-sidebar-controls {
            flex: 0 0 auto;
            display: flex;
            align-items: center;
            justify-content: flex-start;

            @media screen and (orientation:landscape) {
                flex-direction: row;
                width: 100%;
                height: $sidebar-controls-size;
                padding-right: $sidebar-controls-size;
                border-bottom: $controls-border-width solid $background-dark-1;
            }

            @media screen and (orientation:portrait) {
                flex-direction: column;
                width: $sidebar-controls-size;
                height: 100%;
                padding-top: $sidebar-controls-size;
                border-left: $controls-border-width solid $background-dark-1;
            }
        }
    }

    .peers-sidebar-toggle {
        @extend .pr-2;
        @extend .pt-2;

        position: absolute;
        right: 0;
        top: 0;
        border-radius: 0 0 0 $border-radius-std;
        background-color: $background-dark-2;

        @media screen and (orientation:landscape) {
            width: $sidebar-controls-size;
            height: calc(#{$sidebar-controls-size} - #{$controls-border-width});
        }

        @media screen and (orientation:portrait) {
            width: calc(#{$sidebar-controls-size} - #{$controls-border-width});
            height: $sidebar-controls-size;
        }
    }

    .peers-sidebar-control-button {
        flex: 1 1 50%;;
        cursor: pointer;
        width: 100%;
        height: 100%;

        &.disabled {
            pointer-events:none;
            cursor: default;
            opacity: 0.8%;
        }

        .peers-sidebar-button-icon {
            width: 100%;
            height: 100%;
            fill: #fff;
        }
    }
}

@import '../../style.scss';
//@import 'bulma/sass/elements/button.sass';


body {
    --sidebar-width: min(32rem, 100%);
}

.unseen-notification-indicator {
    position: absolute;
    top: 0;
    right: 50%;
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
    overflow: hidden;
    text-align: center;
    color: #fff;
    background-color: rgb(51, 153, 255);
}

.sidebar-body {
    //--body-size: var(--sidebar-width);

    flex: 0 0 auto;
    display: flex;
    flex-direction: column;
    position: absolute;
    width: var(--sidebar-width);
    height: 100%;
    z-index: $z-index-sidebar;
    //background-color: color(var(--col-bg-800), darkness(50%));
    background-color: rgba(var(--col-bg-spread-800), 0.75);
    filter: $shadow;

    @include transition-slide(right, calc(-1 * var(--sidebar-width)));

    //.sidebar-body-slider {
        ////--sidebar-body-offset: calc(-1 * var(--sidebar-width));
        //--transition-right-offset: calc(-1 * var(--sidebar-width));
//
        //display: flex;
        //flex-direction: column;
        //position: absolute;
        //width: var(--sidebar-width);
        //height: 100%;
        //overflow: hidden;

        .sidebar-body-header {
            flex: 0 0 3.5rem;
            display: flex;
            flex-direction: row;
            align-items: center;
            //justify-content: space-between;
            //justify-content: space-evenly;
            //justify-content: center;
            position: relative;
            width: 100%;
            background-color: var(--col-hl-1);

            .title {
                margin: auto;
                font-size: 2rem;
                text-align: center;
                color: var(--col-text-hl-1);
            }

            .close-button {
                $icon-size: 3rem;

                position: absolute;
                left: 0;
                height: $icon-size;
                padding-left: 1rem;
                padding-right: 2rem;

                .close-icon {
                    width: $icon-size;
                    height: $icon-size;
                    fill: var(--col-text-hl-1);
                }
            }
        }

        .sidebar-body-content {
            flex: 1 1 auto;
            position: relative;
            overflow: hidden;
        }
    //}
}

@charset "utf-8";

// Bulma config
//$block-spacing: 1rem;
//$border-light: 1rem;
//$radius-large: 1rem;

@import '~bulma/sass/utilities/_all';
@import '~bulma/sass/base/_all.sass';
@import '~bulma/sass/form/shared.sass';
@import '~bulma/sass/helpers/spacing.sass';

@import '~@fontsource/barlow-condensed/index.css';

//$fa-font-path: "~font-awesome/fonts";
//@import "~@fortawesome/fontawesome-free/scss/fontawesome.scss";
@import "~@fortawesome/fontawesome-free/css/all.min.css";


body {
    font-family: Montserrat, sans-serif;
    color: #1a1b1f;
    font-size: 16px;
    line-height: 28px;
    font-weight: 400;
}

:root {
    overflow: auto;
}

/* Colors */

/* Color templates */
$fg-color: #fff;
//$bg-color: #ec8c94;
//$bg-color: #6699ff;
//$bg-color: #3399ff;
//$bg-color: #ff5050;
$bg-color: #b0413e;
.accent-color-1 {
    color: $fg-color !important;
    //background-color: #CC0000 !important;
    background-color: $bg-color !important;
}

$highlight-1: #b0413e;

$background-dark-1: #222;
$background-dark-2: #444;

$background-dark-900: #111;
$background-dark-800: #222;
$background-dark-700: #2a2a2a;
$background-dark-600: #444;
$background-dark-500: #555;
$background-dark-400: #666;
$background-dark-300: #888;
$background-dark-200: #aaa;
$background-dark-100: #ccc;
$background-dark-50: #eee;

//$background-dark-800: 32, 32, 32;

$background-dark-accent-900: #060c35;
$background-dark-accent-800: #0d1a4e;
$background-dark-accent-700: #152362;
$background-dark-accent-600: #182669;
$background-dark-accent-500: #233385;
$background-dark-accent-400: #2e41a1;
$background-dark-accent-300: #3a4fbf;
$background-dark-accent-200: #465ede;
$background-dark-accent-100: #536dfe;
$background-dark-accent-50: #eee;

//$highlight-1-dark-900: #ffb95d;
//$highlight-1-dark-800: #ffaa5d;
//$highlight-1-dark-700: #ff9b60;
//$highlight-1-dark-600: #ff8c67;
//$highlight-1-dark-500: #ff7d6f;
//$highlight-1-dark-400: #ff6f79;
//$highlight-1-dark-300: #ff6384;
//$highlight-1-dark-200: #f55990;
//$highlight-1-dark-100: #536dfe;

$highlight-1: #ffaa5d;
//$highlight-2: #f55990;
$highlight-2: #f10e5d;
$text-bg: white;
$text-hl-1: $background-dark-accent-800;

//$background-header-dark: #060C35;


:root {
    --col-bg-900: #{$background-dark-900};
    --col-bg-800: #{$background-dark-800};
    --col-bg-700: #{$background-dark-700};
    --col-bg-600: #{$background-dark-600};
    --col-bg-500: #{$background-dark-500};
    --col-bg-400: #{$background-dark-400};
    --col-bg-300: #{$background-dark-300};
    --col-bg-200: #{$background-dark-200};
    --col-bg-100: #{$background-dark-100};
    --col-bg-50: #{$background-dark-50};

    // TODO: don't hardcode this, switch to color-mod once it's available
    --col-bg-spread-800: 32, 32, 32;

    --col-bg-accent-900: #{$background-dark-accent-900};
    --col-bg-accent-800: #{$background-dark-accent-800};
    --col-bg-accent-700: #{$background-dark-accent-700};
    --col-bg-accent-600: #{$background-dark-accent-600};
    --col-bg-accent-500: #{$background-dark-accent-500};
    --col-bg-accent-400: #{$background-dark-accent-400};
    --col-bg-accent-300: #{$background-dark-accent-300};
    --col-bg-accent-200: #{$background-dark-accent-200};
    --col-bg-accent-100: #{$background-dark-accent-100};
    --col-bg-accent-50: #{$background-dark-accent-50};

    //--col-hl1-900: #{$highlight-1-dark-900};
    //--col-hl1-800: #{$highlight-1-dark-800};
    //--col-hl1-700: #{$highlight-1-dark-700};
    //--col-hl1-600: #{$highlight-1-dark-600};
    //--col-hl1-500: #{$highlight-1-dark-500};
    //--col-hl1-400: #{$highlight-1-dark-400};
    //--col-hl1-300: #{$highlight-1-dark-300};
    //--col-hl1-200: #{$highlight-1-dark-200};
    //--col-hl1-100: #{$highlight-1-dark-100};
    //--col-hl1-50: #{$highlight-1-dark-50};

    --col-hl-1: #{$highlight-1};
    --col-hl-2: #{$highlight-2};
    --col-text-bg: #{$text-bg};
    --col-text-hl-1: #{$text-hl-1};

    // Comes with #fff color
    --color-error: #f44336;
}

/* z-Indices */

$z-index-video-chat: 0;
$z-index-conversation-area: 100;
$z-index-sidebar: 200;
$z-index-header: 300;
$z-index-notifications: 400;

/* fonts */

$font-secondary: "Barlow Condensed";

/* Others */

$shadow: drop-shadow(0 0 0.4rem rgba(0, 0, 0, 0.7));

$border-radius-std: 0.25rem;
$border-radius-popup: 0.5rem;

$font-size-normal: 1.2rem;

.svg-icon-light {
    width: 100%;
    height: 100%;
    fill: #fff;
}


/* Main view styles */
//$main-view-header-height: 4rem;

.meetup-container {
    width: 100vw;
    height: 100vh;
    background: linear-gradient(-45deg, var(--col-bg-accent-800), var(--col-bg-800));
}


/* Animation */

@mixin transition-fade($duration: 500ms) {

    .transition-fade-enter {
        opacity: 0;
        visibility: hidden;
    }
    .transition-fade-enter-active {
        opacity: 1;
        transition: opacity $duration;
        visibility: visible;
    }
    .transition-fade-enter-done {
        opacity: 1;
        visibility: visible;
    }
    .transition-fade-exit {
        opacity: 1;
        visibility: visible;
    }
    .transition-fade-exit-active {
        opacity: 0;
        visibility: visible;
        transition: opacity $duration;
    }
    .transition-fade-exit-done {
        opacity: 0;
        visibility: hidden;
    }
}


@mixin transition-size($direction, $size-expanded, $size-collapsed: 0, $duration: 250ms) {

    &.transition-size-enter {
        #{$direction}: $size-collapsed;
    }
    &.transition-size-enter-active {
        #{$direction}: $size-expanded;
        transition: #{$direction} $duration;
    }
    &.transition-size-enter-done {
        #{$direction}: $size-expanded;
    }
    &.transition-size-exit {
        #{$direction}: $size-expanded;
    }
    &.transition-size-exit-active {
        #{$direction}: $size-collapsed;
        transition: #{$direction} $duration;
    }
    &.transition-size-exit-done {
        #{$direction}: $size-collapsed;
    }
}


@mixin transition-slide($direction, $offset, $duration: 250ms) {
    
    &.transition-slide-enter {
        #{$direction}: $offset;
    }
    &.transition-slide-enter-active {
        #{$direction}: 0;
        transition: #{$direction} $duration;
    }
    &.transition-slide-enter-done {
        #{$direction}: 0;
    }
    &.transition-slide-exit {
        #{$direction}: 0;
    }
    &.transition-slide-exit-active {
        #{$direction}: $offset;
        transition: #{$direction} $duration;
    }
    &.transition-slide-exit-done {
        #{$direction}: $offset;
    }
}

/* Shared styles */

.no-content-placeholder {
    @extend .p-2;

    margin: auto;
    text-align: center;
    font-size: 2rem;
    color: #555;
}


.button-flat {
    border: none;
    outline: none;

    &:hover {
        filter: brightness(150%);
    }
}

@import '../../../../style.scss';

.password-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    //justify-content: center;
    width: 100%;
    height: 100%;
}

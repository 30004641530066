@import '../../../../style.scss';

.control-button {
    position: relative;
    width: 3rem;
    height: 3rem;
    margin-left: 1rem;
    margin-right: 0.5rem;
    margin-bottom: 1rem;
    border-radius: 50%;
    border: none;
    outline: none;
    cursor: pointer;
    background-color: #999999;
    //background-color: $background-dark-2;

    &:disabled {
        opacity: 0.6;
        cursor: default;
    }

    .button-icon {
        position: absolute;
        width: 70%;
        height: 70%;
        transform: translate(-50%, -50%);
        fill: #000;
    }
}


@import '../../style.scss';


$toast-width: 30rem;

.toast-container {
    width: 30rem;
    max-width: 80%;
    //top: $main-view-header-height;
    z-index: $z-index-notifications;
    text-align: center;
    //background-color: $background-dark-2;

    .toast-item {
        color: #ccc;
        background-color: $background-dark-1;
        filter: drop-shadow(0 0 0.3rem rgba(0, 0, 0, 0.2));
    }
}

.notification {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    //background-color: $background-dark-2;
    width: 100%;

    .avatar-icon {
        $icon-size: 3rem;

        @extend .mx-1;

        position: relative;
        border-radius: 50%;
        width: $icon-size;
        height: $icon-size;
        font-size: 2rem;
        color: var(--col-hl-1);
    }

    .message-list {
        //@extend .p-2;

        display: flex;
        flex-direction: column;
        margin-left: 1rem;
        
        .message-line {
            width: 100%;
            text-align: left;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            font-size: 1.1rem;
        }
    }
}

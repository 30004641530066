//@import '../resources/mixins';
//@import '../resources/colors';

@import '../../../../style.scss';
//@import '../../../../../node_modules/bulma/sass/form/input-textarea.sass';
@import 'bulma/sass/form/_all.sass';
@import 'bulma/sass/elements/button.sass';


.message-composer {
    $composer-color: $background-dark-2;

    position: relative;
    display: flex;
    flex-direction: row;
    width: 100%;
    max-height: 10rem;

    .left-channel-notification {
        @extend .p-2;

        width: 100%;
        height: 4.5rem;
        font-size: 1.2rem;
        color: #fff;
        background-color: $composer-color;
    }

    .message-form {
        @extend .p-2;

        display: flex;
        flex-direction: row;
        width: 100%;
        background-color: $composer-color;

        .emoji-button {
            @extend .button;
            @extend .p-2;

            width: 3rem;
            border-width: 0;
            background-color: $composer-color;

            .insert-emoji-icon {
                width: 4.5rem;
                height: 4.5rem;
                fill: #ccc;
            }
        }

        .message-text-field {
            @extend .field;
            @extend .p-2;

            flex: 1 1 auto;
            height: 1.2rem;
            max-height: 10rem;
            margin-bottom: 0rem !important;
            overflow-x: hidden;
            overflow-y: auto;
            border-width: 0;
            outline: none;
            font-size: 1.2rem;
            color: #fff;
            background-color: $composer-color;
            resize: none;
        }

        .submit-button {
            @extend .button;

            flex: 0 0 2rem;
            align-self: center;
            border-width: 0;
            background-color: $composer-color;
        }
    }

    .emoji-picker {
        $picker-height: 359px;

        position: absolute;
        top: -$picker-height;
        left: 0;
        height: $picker-height;
        overflow: hidden;
    }
}


//.cc1-chat-win-inpt-int-wrap {
    //display:flex;
    //width: 100%;
//
    //flex-direction: row;
    //align-items: flex-end;
    //position: relative;
    //z-index: 2;
    //padding: 0;
    //min-height: 85px;
//
    //.cc1-chat-win-inpt-wrap {
        //flex: 1 1 auto;
        //position:relative;
        //// width: calc(100% - 200px);
        //// margin: auto 10px;
        //outline: none;
        //border-radius: 8px;
        //border: 1px solid $light-background-border;
        //background-color: $white;
        //display: flex;
        //flex-direction: column;
//
        //width: 100%;
        //min-height: 85px;
        //
        //.cc1-chat-win-inpt-box {
            //width:100%;
            //letter-spacing:-.1px;
            //@include font-15;
            //font-weight: 400;
            //// height: 40px;
            //padding: 15px 10px;
            //outline: none;
            //overflow-x: hidden;
            //overflow-y: auto;
            //position: relative;
            //white-space: pre-wrap;
            //word-wrap: break-word;
            //z-index: 1;
//
            //min-height: 50px;
            //max-height: 100px;
//
            //&:empty:before {
                //content: attr(placeholder);
                //color: rgb(153, 153, 153);
                //pointer-events: none;
                //display: block; [> For Firefox <]
            //}
        //}
//
        //.cc1-chat-win-inpt-box.selectable-text {
            //-webkit-user-select: text;
            //-moz-user-select: text;
            //-ms-user-select: text;
            //user-select: text;
        //}
//
        //.cc1-chat-win-inpt-box.disabled {
            //pointer-events: none;
            //opacity: 0.4;
        //}
//
        //.cc1-chat-win-inpt-box-sticky {
            //padding: 7px 10px;
            //height: 35px;
            //border-top: 1px solid $grey;
            //background-color: $grey;
            //display: flex;
            //flex-direction: row;
            //justify-content: space-between;
//
            //.cc1-chat-win-inpt-attach-wrap {
                //display: flex;
                //flex-direction: row;
//
                //.cc1-chat-win-inpt-attach { 
                    //margin: auto 0;
            //
                    //span {
                        //display:inline-block;
                        //width: 20px;
                        //height: 20px;
                        //background:url(./resources/rounded-plus-grey-icon.svg) center center no-repeat;
                        //cursor: pointer;
                        //img { 
                            //display:none; 
                        //}
                    //}
                //}
    //
                //.cc1-chat-win-file-popup {
                    //left:0;
                    //bottom:0;
                    //position:relative;
                    //width: 0;
                    //border-radius:8px;
                    //overflow:hidden;
                    //z-index:1;
                    //text-align:center;
                    //opacity: 0;
                    //transition: width 0.5s linear;
                //
                    //.cc1-chat-win-file-type-list {
                        //width: 100%;
                        //display: flex;
                        //flex-direction: row;
                        //align-items: center;
                        //justify-content: space-around;
                    //}
                //
                    //.cc1-chat-win-file-type-listitem {
                        //width: 21px;
                        //height: 21px;
                        //background-color: $secondary;
                        //cursor: pointer;
                //
                        //input {
                            //display: none;
                        //}
                    //}
                //
                    //.cc1-chat-win-file-type-listitem.video {
                        //-webkit-mask: url(./resources/video_upload_icon.svg) no-repeat 100% 100%;
                        //mask: url(./resources/video_upload_icon.svg) no-repeat 100% 100%;
                        //-webkit-mask-size: cover;
                        //mask-size: cover;
                    //}
                //
                    //.cc1-chat-win-file-type-listitem.audio {
                        //-webkit-mask: url(./resources/audio_upload_icon.svg) no-repeat 100% 100%;
                        //mask: url(./resources/audio_upload_icon.svg) no-repeat 100% 100%;
                        //-webkit-mask-size: cover;
                        //mask-size: cover;
                    //}
                //
                    //.cc1-chat-win-file-type-listitem.image {
                        //-webkit-mask: url(./resources/images_upload_icon.svg) no-repeat 100% 100%;
                        //mask: url(./resources/images_upload_icon.svg) no-repeat 100% 100%;
                        //-webkit-mask-size: cover;
                        //mask-size: cover;
                    //}
                //
                    //.cc1-chat-win-file-type-listitem.file {
                        //-webkit-mask: url(./resources/document_upload_icon.svg) no-repeat 100% 100%;
                        //mask: url(./resources/document_upload_icon.svg) no-repeat 100% 100%;
                        //-webkit-mask-size: cover;
                        //mask-size: cover;
                    //}
                //}
                //
                //.cc1-chat-win-file-popup.active {
                    //width: 120px;
                    //opacity: 1;
                    //margin: auto 10px;
                //}
            //}
//
            //.cc1-chat-win-inpt-icon-wrap {
                //display: flex;
                //flex-direction: row;
                //align-items: center;
                //justify-content: space-between;
//
                ////.cc1-chat-win-inpt-emoji { 
                    //
                    //.cc1-chat-win-inpt-insert-emoji {
                        //padding: 0 5px;
                        //img{
                            //width: 20px;
                            //height: 20px;
                            //display: inline-block;
                            //cursor: pointer;
                        //}
                    //}
                //// }
//
                //// .cc1-chat-win-inpt-send { 
                    //
                    //.cc1-chat-win-inpt-send-btn {
                        //img{
                            //width: 20px;
                            //height: 18px;
                            //display: inline-block;
                            //cursor: pointer;
                        //}
                    //}
                ////}
            //}
        //}
    //}
//}
//
//.dark {
    //.cc1-chat-win-inpt-box {
        //background-color:$light-grey;
        //box-shadow:$light-grey 0 0 0 1px inset;
        //color:#fff;
    //}
//}

@import '../../style.scss';

.info-screen-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    //background-color: rgba(224, 224, 224, 128);

    .info-screen-center {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 80%;

        .info-screen-message {
            max-width: 100%;
            margin-bottom: 3rem;
            color: #808080;
            font-size: 2rem;
            text-align: center;
            white-space: pre-line;
        }

        .info-screen-icon {
            width: 10rem;
            height: 10rem;
            max-width: 100%;
            max-height: 100%;
        }
    }
}


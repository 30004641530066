@import '../../style.scss';


.meetup-view {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    background-color: #ececec;

    .meetup-header {
        flex: 0 1 auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
        align-content: center;
        position: relative;
        height: 3.8rem;
        padding-top: 0.4rem;
        padding-bottom: 0.4rem;
        background: linear-gradient(45deg, var(--col-bg-accent-700), var(--col-bg-700));
        z-index: $z-index-header;
        filter: $shadow;

        .logo-container {
            flex: 0 1 auto;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;

            .logo-image {
                max-height: 100%;
                padding-left: 1rem;
                padding-right: 1rem;
                padding-top: 0.3rem;
                padding-bottom: 0.3rem;
            }
        }
    }

    .meetup-body {
        flex: 1 1 auto;
        display: flex;
        flex-direction: row;
        position: relative;
        width: 100%;
        height: 100%;
        overflow: hidden;

        .meetup-content {
            flex: 1 1 auto;
            display: flex;
            flex-direction: column;
            position: relative;
            width: 100%;
            height: 100%;
            overflow: hidden;
        }
    }

    .meetup-footer {
        display: flex;
        position: absolute;
        bottom: 0;
        left: 0;
        padding: 0.5rem;
        z-index: $z-index-conversation-area + 50;

        //.leave-button {
            //position: absolute;
            //right: 10px;
            //bottom: 10px;
            //z-index: 10;
            //border-radius: 5px;
            //font-weight: regular;
        //}
    }
}

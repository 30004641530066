@import '../../../../style.scss';


.video-room-controls-container {
    display: flex;
    flex-direction: row;
    position: absolute;
    right: 0;
    bottom: 0;
    margin-right: 0.5rem;
    z-index: 10;

    .termination-button {
        background-color: var(--color-error);
    }
}



.grid-container {
    display: grid;
    position: relative;
    height: 100%;
    box-sizing: border-box;
    justify-items: center;
    align-items: center;

    &.vertical-grid {
        overflow-y: auto;
        overflow-x: hidden;
        grid-auto-flow: row;
    }
    &.horizontal-grid {
        overflow-y: hidden;
        overflow-x: auto;
        grid-auto-flow: row;
    }

    &.small {
        --col-width: 120px;
        --row-height: 80px;
        column-gap: 0.5rem;
        row-gap: 0.5rem;
    }
    &.big {
        //--col-width: 300px;
        //--row-height: 250px;
        --col-width: 20rem;
        --row-height: 20rem;
        //grid-row-gap: 1rem;
        //grid-column-gap: 1rem;
        column-gap: 3rem;
        row-gap: 3rem;
    }

    grid-template-columns: repeat(auto-fit, minmax(var(--col-width), 1fr));
    grid-auto-columns: minmax(var(--col-width), 1fr);
    grid-template-rows: repeat(auto-fit, minmax(var(--row-height), 1fr));
    grid-auto-rows: minmax(var(--row-height), 1fr);
}


@import '../../../../style.scss';

//@import '../resources/mixins';
//@import '../resources/colors';

.message-list {
    display: flex;
    flex-flow: column nowrap;
    position: relative;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    scrollbar-width: none;
    //-ms-overflow-style: none;

    //&::-webkit-scrollbar {
        //display: none;
    //}

    > :first-child {
        margin-top: auto;
    }
}

//.cc1-chat-win-conver-wrap {
//
    //.cc1-chat-win-conver-list-wrap{
        //box-sizing: border-box;
        //display: flex;
        //flex-direction: column;
        //height: 100%;
        //overflow-x: hidden;
        //overflow-y: auto;
        //position: relative;
        //top: 0;
        //transition: background .3s ease-out .1s;
        //width: 100%;
        //z-index: 100;
//
        //.cc1-chat-win-rcvr-row {
            //align-self: flex-start;
        //}
//
        //.cc1-chat-win-sndr-row {
            //align-self: flex-end;
        //}
    //}
//}
//
//.cc1-chat-win-action-msg-wrap {
    //padding:8px 12px;
    //margin-bottom: 16px;
    //@include text-center;
  //
    //.chat-txt-msg {
        //font-size:15px;
        //margin:0;
        //letter-spacing:-.1px;
        //line-height:20px;
    //}
//}
//
//.loading-text {
    //height: 20px;
    //color:$secondary;
    //margin: 0 auto;
    //width: 100px;
    //text-align: center;
    //font-size: 15px;
//}


.self-view-container {
    position: absolute;
    width: 15rem;
    max-width: 15%;
    top: 1rem;
    right: 1rem;
    border-radius: 0.3rem;
    transition: 300ms linear;
    z-index: 200;

    &:hover {
        width: 20rem;
        max-width: 25%;
        transition: 300ms linear;
    }
}

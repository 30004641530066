@import '../../style.scss';


.header-buttons {
    flex: 0 1.5 auto;
    position: relative;
    height: 100%;
    //right: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    //justify-content: center;
    justify-content: flex-end;

    .header-button {
        @extend .button-flat;

        position: relative;
        width: 3rem;
        height: 100%;
        margin-right: 2rem;
        border-radius: 50%;
        background-color: var(--col-bg-500);

        .header-icon {
            position: relative;
            top: 0.4rem;
            height: 100%;
            width: 100%;
            color: var(--col-hl-1);
            font-size: 2.2rem;
        }
    }

    .tooltip-content {
        color: var(--col-text-bg);
        font-size: 1rem;
    }
}


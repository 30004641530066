@import '../../../../style.scss';


.peer-item-container {
    flex: 1 0 30%;
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;

    .active-speaker-indicator {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        outline: 0.3rem solid $bg-color;
        outline-offset: -0.2rem;
        opacity: 50%;
    }

    .video-element {
        position: relative;
        width: 100%;
        height: 100%;

        //-moz-transform: scale(-1, 1);
        //-webkit-transform: scale(-1, 1); -o-transform: scale(-1, 1);
        //transform: scale(-1, 1);
        //filter: flipH;

        &.mirror-video {
            transform: scale(-1, 1);
        }
    }

    .no-video-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: absolute;
        left: 50%;
        top: 50%;
        //max-width: 100%;
        width: 100%;
        transform: translate(-50%, -50%);

        .no-video-avatar-icon {
            $icon-size: 5rem;

            width: $icon-size;
            height: $icon-size;
            border-radius: 50%;
        }
    }


    .peer-item-footer {
        //@extend .m-1;
        @extend .p-1;

        display: flex;
        flex-direction: row;
        align-items: center;
        max-height: 4rem;
        max-width: 100%;;
        margin: 0.1rem;
        //padding: 0.1rem;
        overflow: hidden;
        border-radius: 0.3rem;
        background-color: rgba(160, 160, 160, 0.7);

        &.with-video {
            position: absolute;
            left: 0;
            bottom: 0;
        }

        &.without-video {
            position: relative;
        }

        .participant-name {
            //color: #fff;
            max-width: 100%;
            font-size: 1.1rem;
            font-weight: 500;
            overflow-wrap: break-word;
        }

        .volume-indicator-container {
            $size: 1.5rem;

            position: relative;
            width: $size;
            height: $size;

            .indicator-icon {
                width: 100%;
                height: 100%;
                fill: rgb(255, 51, 51);
            }

            .volume-indicator {
                position: absolute;
                left: 50%;
                top: 50%;
                min-width: 25%;
                min-height: 25%;
                transform: translate(-50%, -50%);
                border-radius: 50%;
                //background-color: #00e673;
                background-color: #4da6ff;
                //transition: width height 500ms;
            }
        }
    }
}

.audio-only-container {
    display: none;
}

@charset "utf-8";

@import '../../../../style.scss';


.participant-interaction-menu {
    $menu-bg-color: #666;

    @include transition-fade(250ms);

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    transform: translateX(-50%);
    width: 5rem;
    height: 3.8rem;
    z-index: $z-index-conversation-area + 50;
    background-color: $menu-bg-color;
    overflow: visible;

    &.is-top {
        bottom: 4rem;
    }

    &.is-bottom {
        top: 4rem;
    }

    .menu-tip {
        position: absolute;
        transform: rotate(-45deg);
        background-color: $menu-bg-color;
        width: 1.5rem;
        height: 1.5rem;
        box-sizing: border-box;
        position: relative;
        bottom: -2rem;
        //left: $participant-avatar-size / 4;

        &.is-top {
            bottom: -1.5rem;
        }

        &.is-bottom {
            top: -1.5rem;
        }
    }

    .menu-content {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        overflow: hidden;
        z-index: $z-index-conversation-area + 60;

        .menu-text {
            text-align: center;
            font-size: 1.2rem;
            color: white;
            background-color: $menu-bg-color;
        }

        .menu-button {
            width: 100%;
            height: 100%;

            &:hover {
                color: #aaa;
                cursor: pointer;
            }
        }
    }
}

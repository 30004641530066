@charset "utf-8";

@import 'bulma/sass/utilities/_all';
@import 'bulma/sass/components/card.sass';
@import 'bulma/sass/elements/button.sass';

@import '../../../../style.scss';


.participant-avatar-container {
    $participant-avatar-size: 7rem;
    $participant-icon-size: 7rem;

    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    z-index: $z-index-conversation-area;

    /* Participant avatar styles */
    .participant-avatar {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
        width: $participant-avatar-size;
        height: $participant-avatar-size;
        //max-height: 120px;
        //max-width: 120px;
        //min-height: 80px;
        //min-width: 80px;
        overflow: hidden;
        border-radius: 50%;
        //margin: 0.2rem;
        //padding: 0.8rem;
        z-index: $z-index-conversation-area + 10;

        .participant-button {
            @extend .button;
        }

        .participant-icon {
            position: relative;
            /*left: auto;*/
            /*max-height: 80px;*/
            /*max-width: 80px;*/
            /*min-height: 60px;*/
            /*min-width: 60px;*/

            //height: $participant-icon-size;
            //width: $participant-icon-size;
            width: 100%;
            height: 100%;

            /*background-image: url("./images/female_head.png");*/
            /*background-image: url("./images/avatar_icons.svg");*/
            /*background-position: 50% 50%;*/
            /*background-size: contain;*/
            /*background-repeat: no-repeat;*/
            //filter: drop-shadow(0 0.2rem $blur-radius rgba(0, 0, 0, 0.2));
            fill: #555;
            object-fit: cover;
            //background-color: $bg-color;
        }

        .participant-label {
            position: absolute;
            bottom: 0;
            width: 100%;
            max-height: 25%;
            padding-left: 1.1rem;
            padding-right: 1.1rem;
            text-align: center;
            font-size: 1.1rem;
            font-weight: 600;
            word-break: break-all;
            hyphens: auto;
            //width: 100px;
            //border-radius: 0.5rem;
            //background-color: #ffffffb0;
            color: var(--col-text-bg);
            background-color: rgba(var(--col-bg-spread-800), 0.75);
            transition: 300ms linear;//max-height;//, padding-left, padding-right;
            font-family: $font-secondary;

            &:hover {
                max-height: 80%;
                padding-left: 0.5rem;
                padding-right: 0.5rem;
            }
        }
    }

    .participant-shadow {
        $blur-radius: 0.4rem;

        position: absolute;
        border-radius: 50%;
        filter: drop-shadow(0.1rem 0.1rem $blur-radius rgba(0, 0, 0, 0.8));

        $extend-size: 0;
        &.is-self-user {
            $extend-size: 0.6rem;

            top: -($extend-size / 2);
            width: $participant-avatar-size + $extend-size;
            height: $participant-avatar-size + $extend-size;
            background-color: var(--col-hl-2);
        }

        &.is-other-user {
            $extend-size: -0.3rem;

            top: -($extend-size / 2);
            width: $participant-avatar-size + $extend-size;
            height: $participant-avatar-size + $extend-size;
            background-color: black;
        }


        .participant-halo {
            //filter: drop-shadow(0 0 0.6rem rgba(10, 250, 10, 1))
            //filter: drop-shadow(0 0 0.6rem $bg-color)
            //outline: 0.3rem solid $bg-color;
            //-moz-outline-radius: 55%;
            //-webkit-outline-radius: 55%;
            box-shadow: 0 0 0.5rem var(--col-hl-2);
        }
    }
}

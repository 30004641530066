//@import '../resources/mixins';
//@import '../resources/colors';

@import '../../../../style.scss';
@import '../../style.scss';


.message-row {
    @extend .px-1;

    display: flex;
    flex-direction: row;
    position: relative;
    width: 100%;

    .avatar-icon {
        width: $avatar-icon-size;
        height: $avatar-icon-size;
        border-radius: 50%;
    }

    &.new-sender-row {
        @extend .mt-2;
        @extend .mb-1;
    }

    &.repeated-sender-row {
        @extend .mt-0;
        @extend .mb-1;
    }

    .message-bubble {
        $bubble-radius: 0.8rem;

        position: relative;
        display: flex;
        flex-direction: column;
        //border-radius: 0.3rem;
        overflow: hidden;

        &.message-sender {
            //align-self: flex-end;
            margin-left: auto;
            max-width: 80%;
            border-radius: $bubble-radius $bubble-radius 0 $bubble-radius;
            color:#fff;
            background-color: rgba(133, 133, 235, 0.92);
        }

        &.message-receiver {
            //align-self: flex-start;
            max-width: 90%;
            border-radius: $bubble-radius $bubble-radius $bubble-radius 0;
            color:#000;//$secondary;
            background-color: rgba(145,145,145,0.92);
            
            &.avatar-compensation {
                margin-left: $avatar-icon-size;
            }
        }

        .message-header {
            @extend .mx-2;

            display: flex;
            flex-direction: row;
            height: 2rem;
            font-size: 1rem;

            .sender-name {
                font-size: 1rem;
                margin-right: 1rem;
            }

        }

        .message-body {
            @extend .m-2;

            display: flex;
            flex-direction: row;
            height: auto;
            overflow: hidden;
            font-size: 1.2rem;
            //min-height: 1rem;
            //line-height: 1.5rem;

            .message-text {
                white-space: pre-line;

                a {
                    color: #0052cc;
                    text-decoration: underline;
                    font-style: italic;
                }

                // Append non-breaking space to not overlap the footer
                &::after {
                    content: "\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0";
                }
            }

            .message-footer {
                position: absolute;
                margin-bottom: 0.3rem;
                bottom: 0;
                right: 0;
                min-width: 5rem;

                .send-time {
                    margin-left: 1rem;
                    font-size: 0.8rem;
                }

                .message-status-indicator {
                    height: 1.5rem;
                    width: 1.5rem;
                }
            }
        }
    }
}

//.chat-row-sender {
    //align-self: flex-end;
//}
//
//.chat-row-receiver {
    //align-self: flex-start;
//}
//
//.chat-row {
    //display: flex;
    //flex-direction: column;
    //margin-bottom: 16px;
    //padding-left: 16px;
    //padding-right: 16px;
    //max-width: 80%;
    ////align-self: flex-end;
//
    //.chat-msg-block {
        //flex: 1 1;
        //display: flex;
        //position: relative;
        //width: 100%;
//
        //.chat-receiver-thumbnail-wrap {
            //width: 36px;
            //float: left;
            //margin: 7px 0;
        //}
//
        //.chat-receiver-avatar-wrap {
            ////width: 36px;
            //float: left;
            //margin: 7px 0;
        //}
//
        //.chat-receiver-details {
            //flex: 1 1;
            //display: flex;
            //flex-direction: column;
            //position:relative;
            //margin: 0 5px;
//
            //.chat-receiver-name-wrap {
                //align-self: flex-start;
//
                //.chat-receiver-name {
                    //font-size: 10px;
                    //color: $secondary;
                //}
            //}
        //}
//
        //.chat-sender-msg-wrap {
            //display:inline-block;
            //border-radius:12px;
            //background-color:rgba(51,153,255,0.92);
            //color:#fff;
            //padding:8px 12px;
            //align-self: flex-end;
            //max-width:100%;
        //}
//
        //.chat-receiver-msg-wrap {
            //display:inline-block;
            //border-radius:12px;
            //color:#000;//$secondary;
            //background-color:rgba(245,245,245,0.92);
            //padding:8px 12px;
            //align-self: flex-start;
            //max-width:100%;
        //}
    //
        //.chat-txt-wrap {
            //.chat-txt-msg {
                //font-size:15px;
                //margin:0;
                //letter-spacing:-.1px;
                //line-height:20px;
                //white-space: pre-wrap;
                //word-wrap: break-word;
                //text-align: left;
                //max-width:100%;
//
                //.emoji {
                    //width: 20px;
                    //height: 20px;
                    //image-rendering: -webkit-optimize-contrast;
                    //display: inline-block;
                    //vertical-align: top;
                    //zoom: 1;
                //}
            //}
//
            //.chat-txt-msg.size1x {
                //img {
                    //width: 48px;
                    //height: 48px;
                //}
            //}
//
            //.chat-txt-msg.size2x {
                //img {
                    //width: 36px;
                    //height: 36px;
                //}
            //}
//
            //.chat-txt-msg.size2x {
                //img {
                    //width: 24px;
                    //height: 24px;
                //}
            //}
        //}
//
        //.chat-img-wrap {
            //img {
                //max-width: 250px;
            //}
            //padding: 2px;
        //}
//
        //.chat-audio-wrap {
            //audio {
                //max-width: 250px;
                //display: inherit;
            //}
        //}
        //
        //.chat-video-wrap {
            //video {
                //max-width: 250px;
                //display: inherit;
            //}
        //}
//
        //.chat-file-wrap {
            //a {
                //color: $text-dark;
                //max-width: 100%;
                //
                //img {
                    //background-color: white;
                //}
            //}
        //}
//
        //.chat-receiver-name-wrap + .chat-receiver-msg-actions {
            //top: -15px;
        //}
    //
        //.chat-msg-time-wrap {
            //align-self: flex-start;
            //
            //.chat-timestamp {
                //display:inline-block;
                //font-size:11px;
                //font-weight:500;
                //letter-spacing:-.1px;
                //line-height:12px;
                //text-transform:uppercase;
                //color: $secondary;
            //}
        //} 
//
        //.chat-msg-actions {
            //left: 0;
        //}
//
        //&:hover .chat-msg-actions {
            //display: inline-flex;
        //}
    //}
//}
//
//
//.dark {
    //.chat-receiver-msg-wrap {
        //background-color:rgba(51,51,51,0.92);
    //}
//} 
//

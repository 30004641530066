@import '../../style.scss';
@import 'bulma/sass/elements/button.sass';
@import 'bulma/sass/components/card.sass';


.invitations-view {
    //@extend .p-3;

    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    height: 100%;

    .invitation {
        //@extend .card;
        @extend .my-1;

        font-size: 1.2rem;
        color: #fff;
        background-color: $background-dark-2;

        .message {
            //@extend .card-content;
            @extend .p-2;

            display: flex;
            flex-direction: row;
            align-items: center;
            text-align: left;

            .emphasis {
                color: #fff;
                font-weight: bold;
            }

            .avatar-icon-img {
                position: relative;
                width: 5rem;
                height: 5rem;
                margin-right: 1rem;
                border-radius: 50%;
                overflow: hidden;
                filter: drop-shadow(0 0 0.3rem rgba(0, 0, 0, 0.4));
            }
        }

        .response {
            //@extend .card-footer;

            display: flex;
            flex-direction: row;

            .response-button {
                //@extend .card-footer-item;
                @extend .button;
                @extend .is-dark;

                flex: 1 1 auto;
                height: 3rem;
                font-size: 1.2rem;
                //width: 50%;
                //border: 0.05rem solid grey;
            }
        }
    }
}

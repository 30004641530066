@import '../resources/mixins';
@import '../resources/colors';

@import '../../../../style.scss';
@import '../../../../../node_modules/bulma/sass/components/panel.sass';


.message-view {
    position: relative;
    display: flex;
    flex-direction: column;
    //height: calc(100% - 4rem);
    width: 100%;;
    height: 100%;
    //@extend .panel;

    .message-view-header {
        flex: 0 0 4rem;
        display: flex;
        flex-direction: row;
        align-items: center;
        //@extend .panel-heading;
        background-color: #ccc;
    }

    .message-list-container {
        flex: 0 1 auto;
        overflow: hidden;
        margin-bottom: auto;
        height: 100%;
    }

    .message-composer-container {
        //margin-top: auto;
        flex: 0 0 auto;
        //position: sticky;
        //bottom: 0;
    }
}

//.cc1-chat-win-header {
    //padding:14px 16px;
    //width:100%;
    //background-color: $light-background;
    //z-index:1;
    //border-bottom: 1px solid $light-background-border;
    //display: flex;
    //flex-direction: row;
    //justify-content: space-between;
//}
//
//.cc1-chat-win-conver-wrap {
    //// padding:5px 16px;
    //// height: calc(100vh - 200px);
    //// overflow-y:auto;
    //// background-color: $light-background;
    //// z-index:1;
    //position:relative;
    //background-color: $light-background;
    //z-index:1;
    //width: 100%;
    //height: 100%;
//
    //flex: 1 1 0;
    //order: 2;
    //position: relative;
//}
//
//.cc1-chat-win-inpt-ext-wrap {
    //padding: 14px 16px;
    //width: 100%;
    //background-color: $light-background;
    //z-index: 1;
//
    //order: 3;
    //position: sticky;
    //bottom: 0px;
    //flex: none;
    //min-height: 105px;
//}
//
//.dark {
//
    //.cc1-chat-win-header {
        //background-color: $dark-background;
        //border-bottom: 1px solid $dark-background-border;
    //}
//
    //.cc1-chat-win-conver-wrap {
        //background-color: $dark-background;
    //}
//
    //.cc1-chat-win-inpt-ext-wrap {
        //background-color: $dark-background;
    //}
//}

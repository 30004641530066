@import '../../../../style.scss';

.video-chat-footer {
    display: flex;
    flex-direction: row;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 10;
}


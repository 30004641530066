@import '../../style.scss';


.conversation-group {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;

    .join-feedback {
        position: absolute;
        width: 100%;
        height: 100%;
        border-radius: 50%;

        &.visible {
            visibility: visible;
            //background-color: rgba(var(--col-bg-spread-800), 0.75);
            background-color: rgba(250, 250, 250, 0.3);
        }

        &.hidden {
            visibility: hidden;
        }
    }

    .conversation-name-box {
        position: absolute;
        display: inline-block;
        width: 70%;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
        font-size: 1.6rem;
        font-weight: regular;
        color: var(--col-bg-600);
    }

    .join-button {
        $button-size: 3rem;

        position: absolute;
        height: 5rem;
        bottom: 0.8rem;;
        left: 50%;
        transform: translateX(-50%);
        width: 5rem;
        height: 3rem;
        //background-color: black;

        &:hover {
            cursor: pointer;
        }

        .join-icon {
            width: 100%;
            height: 100%;
            fill: white;

            &:hover {
                fill: #555;
            }
        }

        .join-message {
            padding-top: 0.7rem;
            color: white;
            text-align: center;
            font-size: 1.2rem;
            font-weight: bold;
        }
    }

    .circle {
        .fill-indicator {
            fill: var(--col-hl-2);
        }
    }

    //.wordcloud-background {
        //position: absolute;
        //width: 100%;
        //height: 100%;
        //top: 0;
        //left: 0;
        //z-index: 0;
        ////transform: translate(-50%, -50%);
    //}
}

@import '../../style.scss';
@import 'bulma/sass/components/card.sass';
@import 'bulma/sass/elements/button.sass';


.name-conversation-background {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: $z-index-header;
    background-color: rgba(64, 64, 64, 0.3);
}

.name-conversation-control {
    //@extend .p-2;
    //@extend .pt-2;

    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    bottom: 10%;
    transform: translate(-50%);
    left: 50%;
    width: 20rem;
    z-index: $z-index-header + 10;
    border-radius: $border-radius-popup;
    overflow: hidden;
    color: #fff;
    background-color: var(--col-bg-600);
    filter: $shadow;
    font-size: 1.2rem;

    .name-conversation-header {
        @extend .my-4;
        text-align: center;
    }

    .name-conversation-form {
        @extend .my-2;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        margin-top: 0.2rem;

        .name-input-field {
            width: 95%;
            border-width: 0;
            border-bottom: 1px solid var(--col-bg-200);
            outline: none;
            text-align: center;
            font-size: 1.2rem;
            color: var(--col-text-bg);
            //background-color: var(--col-bg-700);
            background-color: transparent;
        }
    }

    .name-conversation-buttons {
        @extend .mt-2;

        display: flex;
        justify-content: space-between;
        width: 100%;

        .control-button {
            @extend .m-1;
            //@extend .p-0;
            @extend .button-flat;
            //@extend .m-0;

            flex: 1 1 auto;
            background-color: var(--col-bg-700);
            width: 50%;
            border-radius: 0;

            &:hover {
                filter: brightness(120%);
            }

            .control-button-icon {
                height: 100%;
                width: 100%;

                &.submit {
                    fill: #4da6ff;
                }

                &.cancel {
                    fill: #ff3333;
                }
            }
        }
    }

}

.name-conversation-enter {
    opacity: 0;
    visibility: hidden;
}
.name-conversation-enter-active {
    opacity: 1;
    transition: opacity 250ms;
    visibility: visible;
}
.name-conversation-enter-done {
    opacity: 1;
    visibility: visible;
}
.name-conversation-exit {
    opacity: 1;
    visibility: visible;
}
.name-conversation-exit-active {
    opacity: 0;
    visibility: visible;
    transition: opacity 250ms;
}
.name-conversation-exit-done {
    opacity: 0;
    visibility: hidden;
}

@import '../../style';
@import 'bulma/sass/elements/button.sass';

.video-chat-room-container {
    //@include transition-size(flex-basis, 66%, 0, 500ms);
    @include transition-size(height, var(--video-chat-height), 0, 500ms);
    @include transition-fade;

    flex: 1 0 auto;
    position: relative;
    width: 100%;
    height: var(--video-chat-height);
    z-index: $z-index-video-chat;
    background-color: var(--col-bg-800);

    &.fullscreen {
        --video-chat-height: 100%;
    }

    &.non-fullscreen {
        --video-chat-height: 66%;
    }

    .screen-sharing-info {
        @extend .p-2;

        display: flex;
        position: absolute;
        left: 50%;
        top: 0;
        transform: translate(-50%);
        z-index: $z-index-video-chat + 60;
        font-size: 1.2;
        border-radius: 0 0 0.3rem 0.3rem;
        color: #fff;
        background-color: rgb(51, 153, 255);

        .screen-sharing-end-button {
            margin-left: 1rem;
            font-size: 1.2;
            text-decoration: underline;
            cursor: pointer;
            color: #fff;
            background-color: rgb(51, 153, 255);
        }
    }

    .video-chat-header {
        @extend .p-2;

        display: flex;
        flex-direction: row;
        justify-content: left;
        align-items: center;
        position: absolute;
        //width: 100%;
        height: 3rem;
        top: 0;
        left: 0;
        z-index: $z-index-video-chat + 50;
        border-radius: 0 0 $border-radius-std 0;
        font-size: 1.8rem;
        color: #fff;
        background-color: rgba(64, 64, 64, 0.8);
        //background-color: $background-dark-2;
        //opacity: 50%;

        .edit-button {
            width: 3rem;
            height: 100%;
            margin-left: 1rem;
            cursor: pointer;
            background-color: transparent;
        }
    }
}


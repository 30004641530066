@import '../../style.scss';

.checkin-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    margin: auto;
    overflow: auto;
    //overflow: hidden;
    color: var(--col-text-bg);

    .checkin-view {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 60rem;
        max-width: 100%;
        height: 100%;
        margin-left: 2rem;
        margin-right: 2rem;

        .logo {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            max-width: 90%;
            margin: 2rem;
            margin-bottom: 3rem;

            .logo-image {
                display: block;
                width: 25rem;
                height: content;
                max-width: 100%;
            }

            .logo-subtitle {
                font-size: 1.5rem;
                font-style: italic;
            }

            .decoration-elements {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                width: 100%;

                .image-element {
                    width: 15rem;
                    height: 8rem;
                    margin-left: 3rem;
                    margin-right: 3rem;
                    object-fit: contain;
                }
            }
        }

        .meetup-title {
            text-align: center;
            font-size: 2.5rem;
            margin-top: 1rem;
            margin-bottom: 1rem;
        }

        .start-notice {
            margin-top: 5rem;
            font-size: 1.5rem;
            line-height: 3rem;
            text-align: center;
            color: #666;
        }

        .compatibility-notice {
            max-width: 90%;
            padding: 0.5rem;
            margin-top: 1rem;
            margin-bottom: 1rem;
            border-radius: 0.5rem;
            text-align: center;
            background-color: #ffcc66;
        }

        .form-label {
            display: block;
            //text-align: center;
            font-size: 1.5rem;
            margin-top: 2rem;
            margin-bottom: 0.5rem;
        }

        .checkin-text-input-container {
            display: flex;
            flex-direction: column;
            align-items: center;

            .form-input {
                width: 20rem;
                margin-bottom: 1rem;
                margin-top: 0.5rem;
                text-align: center;
                color: var(--col-text-bg);
                //background-color: rgba(var(--col-bg-spread-800), 0.75);
                //background-color: var(--col-bg-800);
                background-color: transparent;
                outline: none;
                border: 0;
                font-size: 1.2rem;
                border-bottom: 1px solid var(--col-text-bg);
            }

            .form-error {
                color: var(--color-error);
            }

        }

        .form-error-feedback {
            color: var(--color-error);
            margin-top: -1rem;
        }

        .checkin-button {
            @extend .button-flat;

            width: 10rem;
            max-width: 48%;
            margin-top: 1.5rem;
            margin-bottom: 2rem;
            margin-left: 0.2rem;
            margin-right: 0.2rem;
            border-radius: $border-radius-std;
            color: var(--col-bg-accent-800);
            font-size: 1.3rem;
            font-weight: 400;
            //color: var(--col-text-1);
            background-color: var(--col-hl-1);
        }
    }
}

